.Loading {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  h1 {
    font-size: 3rem;
    font-weight: 600;
  }
}
