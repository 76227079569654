/** @format */

@import "~antd/dist/antd.less";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "@blueprintjs/select/lib/css/blueprint-select.css";
@import "@blueprintjs/table/lib/css/table.css";
@import 'handsontable/dist/handsontable.full.min.css';
body::-webkit-scrollbar {
  display: none !important;
}

.App::-webkit-scrollbar {
  display: none !important;
}

.Body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .site-layout-content {
    min-height: 280px;
    padding: 24px;

  }


  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }

  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

  .globe_menu {
    background: linear-gradient(to bottom, #1D4375, #0E1F32);
  }
}
